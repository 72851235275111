<template>
    <div class="row">
      <div class="col-md-12 col-lg-12">
        <q-form ref="editForm">
          <c-card
              title="기계/장비 이력 기본정보"
              class="cardClassDetailForm"
          >
            <template slot="card-button">
              <q-btn-group outline>
                <c-btn
                  v-if="editable"
                  :url="saveUrl"
                  :isSubmit="isSave"
                  :param="data"
                  :mappingType="saveType"
                  label="저장"
                  icon="save"
                  @beforeAction="save"
                  @btnCallback="saveCallback"
                />
                <c-btn
                  v-if="popupParam.heqForkLiftHistoryId"
                  label="삭제"
                  :editable="editable"
                  icon="delete_forever"
                  @btnClicked="remove"
                />
              </q-btn-group>
            </template>
            <template slot="card-detail">
              <div class="col-3">
                <c-field
                  required
                  :editable="editable"
                  :plantCd="popupParam.plantCd"
                  :vendorCd="popupParam.vendorCd"
                  :data="data"
                  label="점검자"
                  userName="inspectionUserName"
                  name="inspectionUserId"
                  v-model="data.inspectionUserId"
                >
                </c-field>
              </div>
              <div class="col-3">
                <c-datepicker
                  required
                  type="date"
                  :editable="editable"
                  label="점검일"
                  name="inspectionDate"
                  v-model="data.inspectionDate"
                >
                </c-datepicker>
              </div>
              <div class="col-6">
                <c-text
                  :editable="editable"
                  label="특이사항"
                  name="inspectionResultDetail"
                  v-model="data.inspectionResultDetail"
                >
                </c-text>
              </div>
            </template>
          </c-card>
          <div class="col-lg-12 col-md-12 col-sm-12">
            <c-table
              ref="table"
              title="점검항목 목록"
              :columns="grid.columns"
              :gridHeight="grid.height"
              :merge="grid.merge"
              :data="data.forkLiftHistoryResultList"
              :filtering="false"
              :isExcelDown="false"
              :columnSetting="false"
              :usePaging="false"
              :editable="editable"
              rowKey="heqForkLiftHistoryResultId"
            >
              <template v-slot:customArea="{ props, col }">
                <template v-if="col.name ==='safetyMeasure'">
                  <c-text-column
                    v-if="props.row['checkValue'] === 'N'"
                    :editable="editable"
                    :props="props"
                    :col="col"
                    :disabled="disabled"
                    v-model="props.row['safetyMeasure']"
                  />
                  <span v-else>
                  </span>
                </template>
                <template v-else-if="col.name==='customCol'">
                  <component
                    :is="imprComponent"
                    :col="col"
                    :props="props"
                    :inputEditable="editable&&!disabled"
                    :isImmShow="true"
                    :requestContentsCols="requestContentsCols"
                    tableKey="heqForkLiftHistoryResultId"
                    ibmTaskTypeCd="ITT0000200"
                    ibmTaskUnderTypeCd="ITTU000225"
                    @imprChange="imprChange"
                  />
                </template>
              </template>
            </c-table>
          </div>
        </q-form>
      </div>
      <c-dialog :param="popupOptions"></c-dialog>
    </div>
</template>
<script>
import { uid } from 'quasar';
import selectConfig from "@/js/selectConfig";
import transactionConfig from "@/js/transactionConfig";
export default {
  name: "forkLiftHistoryResult",
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        heqForkLiftHistoryId: '',
        heqForkLiftId: '',
        facilityTypeCd: '',
        facilityTypeName: '',
        facilityName: '',
        checkValidPeriod: '',
        vendorCd: '',
        plantCd: '',
      }),
    },
  },
  data() {
    return {
      editable: true,
      popupOptions: {
        target: null,
        title: "",
        visible: false,
        top: "",
        param: {},
        closeCallback: null,
      },
      data: {
        heqForkLiftId: '',
        heqForkLiftHistoryId: '',
        insepctionUserId: '',
        insepctionUserName: '',
        insepctionDate: '',
        inspectionResultDetail: '',
        regUserId: '',
        chgUserId: '',

        forkLiftHistoryResultList: [],
      },
      saveUrl: "transactionConfig.sai.machinery.insert.result.url",
      saveType: "POST",
      isSave: false,
      checkUrl: "",
      insertUrl: "",
      disabled: false,
      
    grid: {
      merge: [
        { index: 0, colName: 'typeName' },
      ],
      columns: [
      ],
      data: [],
      height: '600px'
    },
      deleteUrl: "",
      updateUrl: "",
      getUrl: "",
      colorItems: [],
    };
  },

  beforeCreate() {},
  created() {},
  beforeMount() {},
  mounted() {
      this.init();
  },
  beforeDestroy() {},
  computed: {
    // 개선요청 내용 전달
    requestContentsCols() {
      return ['resultName'];
    },
    // 개선요청 Component
    imprComponent() {
      return () => import(`${'@/pages/common/ibm/tableImpr.vue'}`);
    },
  },
  watch: {
  },
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
      this.$comm.getStepItems('IBM_STEP_CD').then(_result => {
        this.colorItems = _result
      });
      this.getUrl = selectConfig.sop.heq.forkLift.result.get.url;
      this.insertUrl = transactionConfig.sop.heq.forkLift.result.insert.url;
      this.updateUrl = transactionConfig.sop.heq.forkLift.result.update.url;
      this.deleteUrl = transactionConfig.sop.heq.forkLift.result.delete2.url;

      this.getDetail();
      this.setHeader();
    },
    getDetail() {
      if (this.popupParam.heqForkLiftHistoryId) {
        this.$http.url = this.$format(this.getUrl, this.popupParam.heqForkLiftHistoryId);
        this.$http.type = 'GET';
        this.$http.request((_result) => {
          this.data = this.$_.clone(_result.data);
        },);
      } else {
        this.stdInfo();
      }
    },
    stdInfo() {
      this.$http.url = selectConfig.sop.heq.inspectitem.list.url;
      this.$http.type = 'GET';
      this.$http.param = {heqTypeCd: this.popupParam.facilityTypeCd};
      this.$http.request((_result) => {
        if (_result.data) {
          this.$_.forEach(_result.data, _item => {
            this.data.forkLiftHistoryResultList.push({
              heqForkLiftHistoryId: this.popupParam.heqForkLiftHistoryId,
              heqForkLiftHistoryResultId: uid(),
              typeName: _item.typeName,
              resultName: _item.inspectItem,
              checkValue: 'NE',
              safetyMeasure: '',
            })
          })
        }
        
      },);
    },
    setHeader() {
        this.grid.columns = [
          {
            name: "typeName",
            field: "typeName",
            label: "구분",
            style: 'width:10%',
            align: "center",
            sortable: false,
          },
          {
            name: "resultName",
            field: "resultName",
            label: "점검항목",
            style: 'width:50%',
            align: "left",
            type: 'html',
            sortable: false,
          },
          {
            required: true,
            setHeader: true,
            name: "checkValue",
            field: "checkValue",
            label: "점검결과",
            style: 'width:10%',
            align: "center",
            type: 'select',
            sortable: false,
            comboItems: [
              { code: 'Y', codeName: '적정' },
              { code: 'N', codeName: '부적정' },
              { code: 'NE', codeName: '해당없음' },
            ]
          },
          {
            name: 'impr',
            field: 'impr',
            label: '개선진행',
            align: 'center',
            child: [
              {
                name: 'customCol',
                field: 'customCol',
                label: '개선번호/개선진행상태',
                align: 'center',
                style: 'width:30%',
                type: 'custom',
                sortable: false
              },
            ]
          },
        ]
    },
    save() {
      if (this.popupParam.heqForkLiftHistoryId) {
        this.saveUrl = this.updateUrl;
        this.saveType = "PUT";
      } else {
        this.saveUrl = this.insertUrl;
        this.saveType = "POST";
      }
      this.$refs["editForm"].validate().then((_result) => {
        if (_result) {
          window.getApp.$emit("CONFIRM", {
            title: "확인",
            message: "저장하시겠습니까?",
            
            type: "info", // success / info / warning / error
            // 확인 callback 함수
            confirmCallback: () => {
              this.data.heqForkLiftId = this.popupParam.heqForkLiftId;
              this.data.heqForkLiftHistoryId = this.popupParam.heqForkLiftHistoryId;
              this.data.regUserId = this.$store.getters.user.userId;
              this.data.chgUserId = this.$store.getters.user.userId;
              this.isSave = !this.isSave;
            },
            // 취소 callback 함수
            cancelCallback: () => {},
          });
        } else {
            window.getApp.$emit("APP_VALID_ERROR");
        }
      });
    },
    saveCallback(_result) {
      window.getApp.$emit("APP_REQUEST_SUCCESS");
      this.popupParam.heqForkLiftHistoryId = _result.data;
      this.getDetail()
    },
    remove() {
      window.getApp.$emit("CONFIRM", {
        title: "확인",
        message: "삭제하시겠습니까?",
        
        type: "warning", // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
          this.$http.url = this.$format(
            this.deleteUrl,
            this.popupParam.heqForkLiftHistoryId
          );
          this.$http.type = "DELETE";
          this.$http.request(() => {
            window.getApp.$emit("APP_REQUEST_SUCCESS");
            this.$emit("closePopup");
          });
        },
        // 취소 callback 함수
        cancelCallback: () => {},
      });
    },
    // 개선창 닫기 후
    imprChange() {
      this.$refs['table'].$refs['compo-table'].resetVirtualScroll();
    },
  },
};
</script>
